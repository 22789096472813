import React, { Component } from "react";
import css from './loader.module.scss';

export class Loader extends Component {
    
    constructor(props){
        super(props)
    }

    render(){
        return (
            <>
            <svg xmlns="http://www.w3.org/2000/svg"
             fill="none" viewBox="0 0 26 26"
             {...this.props}
              stroke="currentColor" strokeLinecap="round"
               className={`${css.loader} max-w-full max-h-full text-accent`}
                strokeWidth="2">
                    <circle stroke={'#00000040'} strokeWidth={1} r="12" cx="13" cy="13"></circle>
                    <circle stroke={'#375aef'} strokeDasharray={33} r="12" cx="13" cy="13"></circle>
            </svg>
            </>
        )
    }
}