import React, { Component, Suspense, lazy, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Posizioni = lazy(() => import('./dashboard/Posizioni'));
const DashboardTwo = lazy(() => import('./dashboard/DashboardTwo'));
const Dashboarduser = lazy(() => import("./dashboard/Dashboarduser"))



const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));


const BasicElementsUpdate = lazy(() => import('./form-elements/BasicElementsUpdate'));
const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicElementsUpdateTwo = lazy(() => import('./form-elements/BasicElementsUpdateTwo'));
const BasicElementsTwo = lazy(() => import('./form-elements/BasicElementsTwo'));

const BasicElementsUpdateThree = lazy(() => import('./form-elements/BasicElementsUpdateThree'));
const BasicElementsThree = lazy(() => import('./form-elements/BasicElementsThree'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const BasicTableMessage = lazy(() => import("./tables/BasicTablemessage"));
const Inbox = lazy(() => import("./tables/Inbox"));
const ViewInbox = lazy(() => import("./tables/ViewInbox"));
const ViewMessage = lazy(() => import("./tables/ViewMessage"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const VerifyEmail = lazy(() => import('./user-pages/VerifyEmail'));
const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const ForgetPassword = lazy(() => import("./user-pages/ForgetPassword"));
const NewPassword = lazy(() => import("./user-pages/NewPassword"));

const BlankPage = lazy(() => import("./general-pages/BlankPage"));

const ProtectedRoute = ({ isAdmin, component: Component, ...rest }) => {
	const test = useSelector(
		(state) => state.userState
	);

	const [company,setCompany] = useState(null);

	const { isLoading, isAuthenticated,user } = test;

	useEffect(() => {
		if(!user?._id){
			return;
		}
		const getCompany = () => {
		axios.get(`${process.env.REACT_APP_URL}/v1/user/${user._id}/company`,
		{withCredentials: true}).then(data => setCompany(data.data.company));
		}
		getCompany();
	},[user?._id])
	

	return (
		<Fragment>
			{(!isLoading) && (
				<Route
					{...rest}
					render={(props) => {
						
						if (!isAuthenticated && user) {
							console.log('user . ', user)
							return <Redirect to="/dashboard" />;
						}
						/**
						if(user.role === 'user' && props.match.path === '/dashboard' && company ){
							return <Redirect to={`/basic-elements/${company?._id}`}/>
						} 
						 */
						return <Component {...props} />;
					}}
				/>
			)}
		</Fragment>
	);
};

class AppRoutes extends Component {
	render() {
		return (
			<Suspense fallback={<Spinner />}>
				<Switch>
					
					<Route path="/user-pages/login-1" component={Login} />

					<ProtectedRoute
						exact
						path="/dashboard"
						component={Dashboard}
					/>
					{/*
					<ProtectedRoute
						exact
						path="/posizioni"
						component={Posizioni}
					/>

					<ProtectedRoute
						exact
						path="/dashboard-2"
						component={DashboardTwo}
					/>
					*/}

					<Route path="/basic-ui/buttons" component={Buttons} />
					<Route path="/basic-ui/dropdowns" component={Dropdowns} />
					<Route path="/basic-ui/typography" component={Typography} />

					<ProtectedRoute
						path="/form-Elements/basic-elements"
						component={BasicElements}
					/>
					<ProtectedRoute
						path="/basic-elements/:id"
						component={BasicElementsUpdate}
					/>
					{/*
					<ProtectedRoute
						path="/form-Elements/basic-elements-2"
						component={BasicElementsTwo}
					/>
					<ProtectedRoute
						path="/basic-elements-2/:id"
						component={BasicElementsUpdateTwo}
					/>

					<ProtectedRoute
						path="/form-Elements/basic-elements-3"
						component={BasicElementsThree}
					/>
					<ProtectedRoute
						path="/basic-elements-3/:id"
						component={BasicElementsUpdateThree}
					/>
					*/}
					<ProtectedRoute
						path="/tables/basic-table"
						component={BasicTable}
					/>
					<ProtectedRoute
						path="/tables/basic-table-3"
						component={BasicTableMessage}
					/>
					<ProtectedRoute
						path="/tables/basic-table-4"
						component={Inbox}
					/>
					<ProtectedRoute
						path="/messages/admin"
						component={ViewMessage}
					/>
					<ProtectedRoute path="/inbox/admin" component={ViewInbox} />
					<ProtectedRoute
						exact
						path="/tables/users"
						component={Dashboarduser}
					/>

					<Route path="/icons/mdi" component={Mdi} />

					<Route path="/charts/chart-js" component={ChartJs} />

					<Route
						path="/user-pages/register-1"
						component={Register1}
					/>
					<Route
						path="/user-pages/password/forget"
						component={ForgetPassword}
					/>
					<Route
						path="/user-pages/password/new/:token"
						component={NewPassword}
					/>

					<Route path="/error-pages/error-404" component={Error404} />
					<Route path="/error-pages/error-500" component={Error500} />

					<Route
						path="/general-pages/blank-page"
						component={BlankPage}
					/>
                    <Route path="/email/verify/:id" component={VerifyEmail} />


					<Redirect to="/dashboard" />
				</Switch>
			</Suspense>
		);
	}
}

export default AppRoutes;