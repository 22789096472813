import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
	user: state.userState.user,
	allFormData: state.userState.allFormData,
	hasUnreadMessage: state.userState.hasUnreadMessage,
});

class Sidebar extends Component {
	state = {};

	toggleMenuState(menuState) {
		if (this.state[menuState]) {
			this.setState({ [menuState]: false });
		} else if (Object.keys(this.state).length === 0) {
			this.setState({ [menuState]: true });
		} else {
			Object.keys(this.state).forEach((i) => {
				this.setState({ [i]: false });
			});
			this.setState({ [menuState]: true });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		document.querySelector("#sidebar").classList.remove("active");
		Object.keys(this.state).forEach((i) => {
			this.setState({ [i]: false });
		});

		const dropdownPaths = [
			{ path: "/apps", state: "appsMenuOpen" },
			{ path: "/basic-ui", state: "basicUiMenuOpen" },
			{ path: "/advanced-ui", state: "advancedUiMenuOpen" },
			{
				path: "/form-elements/basic-elements",
				state: "formElementsMenuOpen",
			},
			{
				path: "/form-elements/basic-elements-3",
				state: "formElementsThreeMenuOpen",
			},
			{
				path: "/form-elements/basic-elements-2",
				state: "formElementsTwoMenuOpen",
			},
			{ path: "/tables/basic-table", state: "tablesMenuOpen" },
			{ path: "/maps", state: "mapsMenuOpen" },
			{ path: "/icons", state: "iconsMenuOpen" },
			{ path: "/charts", state: "chartsMenuOpen" },
			{ path: "/user-pages", state: "userPagesMenuOpen" },
			{ path: "/error-pages", state: "errorPagesMenuOpen" },
			{ path: "/general-pages", state: "generalPagesMenuOpen" },
			{ path: "/ecommerce", state: "ecommercePagesMenuOpen" },
		];

		dropdownPaths.forEach((obj) => {
			if (this.isPathActive(obj.path)) {
				this.setState({ [obj.state]: true });
			}
		});
	}


	render() {
		console.log('user .... ',this.props);
		// console.log('user .... ',this.props.user?._id);
		return (
			<nav className="sidebar sidebar-offcanvas" id="sidebar">
				<ul className="nav">
					{this.props.user && 
					<li className="nav-item nav-profile">
						<a
							href="!#"
							className="nav-link"
							onClick={(evt) => evt.preventDefault()}>
							<div className="nav-profile-image">
								<img
									src={require("../../assets/images/apptoyoulogo.jpg")}
									alt="profile"
								/>
								<span className="login-status online"></span>{" "}

							</div>
							<div className="nav-profile-text">
								<span className="font-weight-bold mb-2">
									<Trans>{this.props.user?.sureName}</Trans>
								</span>
								{this.props.user?.isConnectAlliance && 
								<span className="text-secondary text-small">
									<Trans>Connect Alliance</Trans>
								</span>
								}
							</div>
							<i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
						</a>
					</li>
					}
					<li
						className={
							this.isPathActive("/dashboard")
								? "nav-item active"
								: "nav-item"
						}>
						<Link className="nav-link" to="/dashboard">
							<span className="menu-title">
								<Trans>
									{this.props.user?.role === "admin"
										? "Cerca Aziende"
										: "Ricerca Partner"}
								</Trans>
							</span>
							<i className="mdi mdi-keyboard menu-icon"></i>
						</Link>
					</li>
					
					{/*
					<li
						className={
							this.isPathActive("/tables/basic-table-4")
								? "nav-item active"
								: "nav-item"
						}>
						
						<Link className="nav-link" to="/tables/basic-table-4">
							<span className="menu-title">
								<Trans>Centro richieste</Trans>
							</span>
							<i
								className="mdi mdi-keyboard menu-icon"
								style={{
									color: this.props.hasUnreadMessage
										? "red"
										: "unset",
								}}></i>
						</Link>
					</li>
					
					{this.props.user?.role === "admin" && (
						<>
							
							<li
								className={
									this.isPathActive("/inbox/admin")
										? "nav-item active"
										: "nav-item"
								}>
								<Link className="nav-link" to="/inbox/admin">
									<span className="menu-title">
										<Trans>Dashboard Inbox</Trans>
									</span>
									<i className="mdi mdi-keyboard menu-icon"></i>
								</Link>
							</li>
						</>
					)}
					 
					{this.props.user?.role === "admin" && (
						<>
							<li
								className={
									this.isPathActive("/dashboard-2")
										? "nav-item active"
										: "nav-item"
								}>
								<Link className="nav-link" to="/dashboard-2">
									<span className="menu-title">
										<Trans>Cerca Candidati</Trans>
									</span>
									<i className="mdi mdi-account-card-details menu-icon"></i>
								</Link>
							</li>
							<li
								className={
									this.isPathActive("/posizioni")
										? "nav-item active"
										: "nav-item"
								}>
								<Link className="nav-link" to="/posizioni">
									<span className="menu-title">
										<Trans>Cerca Posizioni</Trans>
									</span>
									<i className="mdi mdi-animation menu-icon"></i>
								</Link>
							</li>
						</>
					)}
					*/}
					{this.props?.user && 
					<li
						className={
							this.isPathActive("/form-elements/basic-elements")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.formElementsMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() =>
								this.toggleMenuState("formElementsMenuOpen")
							}
							data-toggle="collapse">
								
							<span className="menu-title">
								
								<Trans>
									{this.props.user?.role === "admin"
										? "Inserisci azienda"
										: "Profilo azienda"}
								</Trans>
								
							</span>
							<i className="menu-arrow"></i>
							<i className="mdi mdi-account-plus"></i>
						</div>
						<Collapse in={this.state.formElementsMenuOpen}>
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									{" "}
									<Link
										className={
											this.isPathActive(
												this.props.user?.role ===
													"admin"
													? "/form-elements/basic-elements"
													: this.props.allFormData?.filter(item => item.createdBy === this.props.user?._id)?.length > 0
													? `/basic-elements/${this.props.allFormData?.filter(item => item.createdBy === this.props.user?._id)[0]?._id}`
													: "/form-elements/basic-elements"
											)
												? "nav-link active"
												: "nav-link"
										}
										to={
											this.props.user?.role === "admin"
												? "/form-elements/basic-elements"
												: this.props.allFormData?.filter(item => item.createdBy === this.props.user?._id)?.length > 0
												? `/basic-elements/${this.props.allFormData?.filter(item => item.createdBy === this.props.user?._id)[0]?._id}`
												: "/form-elements/basic-elements"
										}>
										{this.props.user?.role === "admin" ? (
											<Trans>Nuovo inserimento</Trans>
										) : (
											<Trans>modifica profilo</Trans>
										)}
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					}
					
					
					{this.props.user?.role === "admin" && (
						<>
						{/*
							<li
								className={
									this.isPathActive(
										"/form-elements/basic-elements-2"
									)
										? "nav-item active"
										: "nav-item"
								}>
								<div
									className={
										this.state.formElementsTwoMenuOpen
											? "nav-link menu-expanded"
											: "nav-link"
									}
									onClick={() =>
										this.toggleMenuState(
											"formElementsTwoMenuOpen"
										)
									}
									data-toggle="collapse">
									<span className="menu-title">
										<Trans>Inserisci candidato</Trans>
									</span>
									<i className="menu-arrow"></i>
									<i className="mdi mdi-account-plus"></i>
								</div>
								<Collapse
									in={this.state.formElementsTwoMenuOpen}>
									<ul className="nav flex-column sub-menu">
										<li className="nav-item">
											{" "}
											<Link
												className={
													this.isPathActive(
														"/form-elements/basic-elements-2"
													)
														? "nav-link active"
														: "nav-link"
												}
												to="/form-elements/basic-elements-2">
												<Trans>Nuovo inserimento</Trans>
											</Link>
										</li>
									</ul>
								</Collapse>
							</li>

							<li
								className={
									this.isPathActive(
										"/form-elements/basic-elements-3"
									)
										? "nav-item active"
										: "nav-item"
								}>
								<div
									className={
										this.state.formElementsThreeMenuOpen
											? "nav-link menu-expanded"
											: "nav-link"
									}
									onClick={() =>
										this.toggleMenuState(
											"formElementsThreeMenuOpen"
										)
									}
									data-toggle="collapse">
									<span className="menu-title">
										<Trans>Inserisci posizione</Trans>
									</span>
									<i className="menu-arrow"></i>
									<i className="mdi mdi-account-plus"></i>
								</div>
								<Collapse
									in={this.state.formElementsThreeMenuOpen}>
									<ul className="nav flex-column sub-menu">
										<li className="nav-item">
											{" "}
											<Link
												className={
													this.isPathActive(
														"/form-elements/basic-elements-3"
													)
														? "nav-link active"
														: "nav-link"
												}
												to="/form-elements/basic-elements-3">
												<Trans>Nuovo inserimento</Trans>
											</Link>
										</li>
									</ul>
								</Collapse>
							</li>
						*/}
							<li
								className={
									this.isPathActive("/tables/basic-table")
										? "nav-item active"
										: "nav-item"
								}>
								<div
									className={
										this.state.tablesMenuOpen
											? "nav-link menu-expanded"
											: "nav-link"
									}
									onClick={() =>
										this.toggleMenuState("tablesMenuOpen")
									}
									data-toggle="collapse">
									<span className="menu-title">
										<Trans>Tabelle Admin</Trans>
									</span>
									<i className="menu-arrow"></i>
									<i className="mdi mdi-table-large menu-icon"></i>
								</div>
								<Collapse in={this.state.tablesMenuOpen}>
									<ul className="nav flex-column sub-menu">
										<li className="nav-item">
											{" "}
											<Link
												className={
													this.isPathActive(
														"/tables/basic-table"
													)
														? "nav-link active"
														: "nav-link"
												}
												to="/tables/basic-table">
												<Trans>
													Aggiungi parole ai domini
												</Trans>
											</Link>
										</li>

										<li className="nav-item">
											{" "}
											<Link
												className={
													this.isPathActive(
														"/tables/users"
													)
														? "nav-link active"
														: "nav-link"
												}
												to="/tables/users">
												<Trans>Tabella Utenti</Trans>
											</Link>
										</li>
									</ul>
								</Collapse>
							</li>
						</>
					)}
				</ul>
			</nav>
		);
	}

	isPathActive(path) {
		return this.props.location.pathname === path;
	}

	componentDidMount() {
		this.onRouteChanged();
		// add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
		const body = document.querySelector("body");
		document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
			el.addEventListener("mouseover", function () {
				if (body.classList.contains("sidebar-icon-only")) {
					el.classList.add("hover-open");
				}
			});
			el.addEventListener("mouseout", function () {
				if (body.classList.contains("sidebar-icon-only")) {
					el.classList.remove("hover-open");
				}
			});
		});
	}
}

export default connect(mapStateToProps)(withRouter(Sidebar));
