import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isAuthenticated: false,
	isLoading: true,
	user: undefined,
	allFormData: [],
	allPosizioniData: [],
	userForm: [],
	replied: [],
	hasUnreadMessage: false,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setLoader: (state, action) => {
			state.isLoading = action.payload;
		},
		setUser: (state, action) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		logoutUser: (state, action) => {
			state.user = undefined;
			state.isAuthenticated = false;
			// state.allFormData = [];
			state.allPosizioniData = [];
			state.userForm = [];
			state.replied = [];
			state.hasUnreadMessage = false;
		},
		setAllFormData: (state, action) => {
			state.allFormData = action.payload;
		},
		setAllPosizioniData: (state, action) => {
			state.allPosizioniData = action.payload;
		},
		setAllCandidate: (state, action) => {
			state.allCandidate = action.payload;
		},
		setUserForm: (state, action) => {
			state.userForm = action.payload;
		},
		setReplied: (state, action) => {
			state.replied = action.payload;
		},
		setHasUnreadMessage: (state, action) => {
			state.hasUnreadMessage = action.payload;
		},
	},
});

export const {
	setLoader,
	setUser,
	logoutUser,
	setAllFormData,
	setAllCandidate,
	setAllPosizioniData,
	setUserForm,
	setReplied,
	setHasUnreadMessage,
} = userSlice.actions;

export default userSlice.reducer;