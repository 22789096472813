import React from "react";
import { Loader } from "./Loader";

export class LoaderContainer extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <>
            <div style={{zIndex: 1000,
                    position: this.props.position || 'fixed',
                    left: 0,right: 0,bottom:0,top:0,backgroundColor: '#ffffffcf',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
                <Loader width={40}/>
            </div>
            </>
        )
    }
}