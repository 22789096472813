import React, { Component } from "react"

export class ModalComponent extends Component {

    constructor(props) {
        super(props);
        //console.log(this.props)
    }
    
    render() {
        return (
        <>
        <div style={{zIndex: 10000,
                    flexWrap: 'wrap',
                    padding: 50,
                    overflowY: 'auto',
                    maxHeight: '100vh',
                    position: 'fixed',
                    left: 0,right: 0,bottom:0,top:0,backgroundColor: '#00000070',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
            <div style={{display: 'flex',
            position:'relative',
            gap:10,
            flexDirection: 'column',
            width: this.props.width || 700,
            maxWidth: '100%',
            padding:20,
            boxShadow: '1px 1px 1px lightgrey',
            backgroundColor: 'white'}}>
                <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',flexDirection: 'row'}}>
                    <h4 style={{color: '#198ae3',fontWeight:600}}>{this.props.title || ''}</h4>
                    <i onClick={() => {
                        if(this.props.onClose) {
                        this.props.onClose();
                        }
                    }} className="mdi mdi-close cursor-pointer" style={{fontSize:20, color: '#198ae3'}}></i>
                </div>
                {this.props.children}
            </div>
        </div>
        </>
        )
    }
}

export default ModalComponent;