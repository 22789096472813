export const filterObject = ( object ) => {
    for ( const prop in object ) {
        const element = object[ prop ]
        if ( element != null ) continue 
        delete object[ prop ]
    }
    return object
}


export const isValidEmail = ( email ) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test( email )

export const isValidPhoneNumber = (phoneNumber) => 
/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phoneNumber.toString().replace( /\s/g, '' ) );


export const DateToInputHtmlDateTime = (inputDate) => {
    return new Date(
        inputDate.getTime() - inputDate.getTimezoneOffset() * 60000
      ).toISOString().slice( 0, -1 )
    
}