import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';


const reducer = {
    userState: userReducer
}

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})

export default store;