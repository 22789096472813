import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LoaderContainer } from '../tables/components/Ui/loader/LoaderContainer';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { logoutUser, setAllFormData } from '../redux/slices/userSlice';
import axios from 'axios';
import ModalComponent from '../tables/components/Ui/ModalComponent';
import { isValidEmail, isValidPhoneNumber } from '../lib/utils';

const withNavigation = (Component) => {
    return props => <Component {...props} history={useHistory()} />;
}

const mapStateToProps = (state) => ({
	user: state.userState.user,
	hasUnreadMessage: state.userState.hasUnreadMessage,
});

const mapDispatchToProps = (dispatch) => ({
	logoutUserAction: () => dispatch(logoutUser()),
	setAllFormDataAction: (formData) => dispatch(setAllFormData(formData)),
});


export class FeedbackModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
        <>
        <div style={{zIndex: 10000,
                    flexWrap: 'wrap',
                    padding: 50,
                    overflowY: 'auto',
                    maxHeight: '100vh',
                    position: 'fixed',
                    left: 0,right: 0,bottom:0,top:0,backgroundColor: '#00000070',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
            <div style={{display: 'flex',
            position:'relative',
            gap:10,
            flexDirection: 'column',
            width: this.props.width || 400,
            maxWidth: '100%',
            padding:20,
            boxShadow: '1px 1px 1px lightgrey',
            backgroundColor: 'white'}}>
                <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',flexDirection: 'row'}}>
                    <h4 style={{color: '#198ae3',fontWeight:600}}>{this.props.title || ''}</h4>
                    <i onClick={() => {
                        if(this.props.onClose) {
                        this.props.onClose();
                        }
                    }} className="mdi mdi-close cursor-pointer" style={{fontSize:20, color: '#198ae3'}}></i>
                </div>  
				<p>Vi contatteremo al più presto!</p>
            </div>
        </div>
        </>
        )
    }
}

class Navbar extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isLoading: false,
			contactUsIsOpen: false,
			feedbackShown: false,
			nameContactUs: '',
			surnameContactUs: '',
			emailContactUs: '',
			phoneNumberContactUs: '',
			messageContactUs: '',
		}
	}

	componentDidMount() {
		if (!this.props?.user){
			document.body.classList.add("sidebar-icon-only")
		}
	}

	toggleOffcanvas() {
		document.querySelector(".sidebar-offcanvas").classList.toggle("active");
	}
	toggleRightSidebar() {
		document.querySelector(".right-sidebar").classList.toggle("open");
	}

	logoutHandler = async (e) => {
		try {
			await axios.get(`${process.env.REACT_APP_URL}/v1/user/logout`, {
				withCredentials: true,
			});

			this.props.logoutUserAction();
			this.props.history.push("/dashboard");
		} catch (err) {
			console.log(err);
		}
	};

	sendRequest = async () => {
		console.log(this.state)
		this.setState({
			isLoading: true
		})
		if(!isValidEmail(this.state.emailContactUs)){
			return alert('Email non valida');
		}
		if(!isValidPhoneNumber(this.state.phoneNumberContactUs)){
			return alert('Inserire un numero di telefono valido')
		}
		if(!this.state.nameContactUs){
			return alert('Inserire un nome');
		}
		if(!this.state.surnameContactUs){
			return alert('Inserire un cognome');
		}
		if(!this.state.messageContactUs){
			return alert('Inserire un messaggio');
		}

		const res = await axios.post(
				`${process.env.REACT_APP_URL}/v1/contact-us`,
				{
					firstName: this.state.nameContactUs,
					lastName: this.state.surnameContactUs,
					email: this.state.emailContactUs,
					text: this.state.messageContactUs,
					phoneNumber: this.state.phoneNumberContactUs
				},
			);
		if (res.status == 200){
			this.setState({feedbackShown: true})
			setTimeout(() => {
				this.setState({contactUsIsOpen: false, feedbackShown: false})
			}, "3000");	 
		}
		this.setState({
			isLoading: false
		})
	}


	render() {

		return (
			<>
			<nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
				<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
					{/* <Link className="navbar-brand brand-logo" to="/"></Link>
					 */}
					 <></>
					{/* <Link
						className="navbar-brand brand-logo-mini"
						to="/"></Link> */}
					<></>
				</div>
				<div className="navbar-menu-wrapper d-flex align-items-stretch">
					{ this.props.user &&
					<button
						className="navbar-toggler navbar-toggler align-self-center"
						type="button"
						onClick={() =>
							document.body.classList.toggle("sidebar-icon-only")
						}>
						<span className="mdi mdi-menu"></span>
					</button>
					}
					<ul className="navbar-nav navbar-nav-right">
						{this.props.user ? 
						<li className="nav-item nav-profile">
							<Dropdown alignRight>
								<Dropdown.Toggle className="nav-link">
									<div className="nav-profile-img">
										<img
											src={require("../../assets/images/apptoyoulogo.jpg")}
											alt="user"
										/>
										<span className="availability-status online"></span>
									</div>
									<div className="nav-profile-text">
										<p className="mb-1 text-black">
											<Trans>
												{this.props.user?.sureName}
											</Trans>
										</p>
									</div>
								</Dropdown.Toggle>
							
								<Dropdown.Menu className="navbar-dropdown">
									<Dropdown.Item onClick={this.logoutHandler}>
										<i className="mdi mdi-logout mr-2 text-info"></i>
										<Trans>Esci</Trans>
									</Dropdown.Item>
								</Dropdown.Menu>
							
							</Dropdown>
						</li>
						: 
						<>
						<li style={{marginRight:10}}>
							<div onClick={() => {
								this.setState({
									contactUsIsOpen: true
								})
							}} style={{borderRadius:10,backgroundColor:'#198ae3',padding:10, cursor: 'pointer'}}>
								<span style={{color: 'white'}}>Contattaci</span>
							</div>
						</li>
						<li>
							<Link to={'user-pages/login-1'}>Login</Link>
						</li> 
						</>
						}
					</ul>
					<button
						className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
						type="button"
						onClick={this.toggleOffcanvas}>
						<span className="mdi mdi-menu"></span>
					</button>
				</div>
			</nav>

			{this.state.contactUsIsOpen && 
			<ModalComponent title="Contattaci" width={500} onClose={() => this.setState({
				contactUsIsOpen: false
			})}>
			{this.state.feedbackShown && 
				<FeedbackModal 
				title="Grazie per la richiesta inoltrata!"
				onClose={() => this.setState({
					feedbackShown: false
				})} />}
			<div style={{display:'flex',flex:1,flexDirection:'column', gap:13, padding: '10px'}}>
				<div style={{display:'flex', flex: 1, flexDirection: 'row', justifyContent:'space-between', gap: 5}}>
					<div style={{display:'flex',flexDirection:'column', gap: 5, alignItems:'flex-start'}}>
						<span style={{textAlign:'left',fontWeight:500,color: '#198ae3', }}>Nome</span>
						<input style={{borderRadius:10,border:'1px solid lightgray', fontSize: '14px', padding:5}} placeholder='John' type='texts' onChange={(e) => {
							this.setState({
								nameContactUs: e.target.value
							})
						}}></input>
					</div>
					<div style={{display:'flex',flexDirection:'column', gap: 5,alignItems:'flex-start'}}>
							<span style={{textAlign:'left',fontWeight:500,color: '#198ae3',}}>Cognome</span>
							<input style={{borderRadius:10,border:'1px solid lightgray',fontSize: '14px', padding: 5}} placeholder='Doe'  type='text' onChange={(e) => {
								this.setState({
									surnameContactUs: e.target.value
								})
							}}></input>
					</div>
				</div>
				<div style={{display:'flex',flexDirection:'column', gap: 5, flex:0.5,alignItems:'left'}}>
					<span style={{textAlign:'left',fontWeight:500,color: '#198ae3',flex:1}}>Email</span>
					<input style={{flex:2,borderRadius:10,border:'1px solid lightgray',fontSize: '14px',padding:5}} placeholder='john.doe@example.com' type='email' onChange={(e) => {
						this.setState({
							emailContactUs: e.target.value
						})
					}}></input>
				</div>
				<div style={{display:'flex',flexDirection:'column', gap: 5, flex:1,alignItems:'left'}}>
					<span style={{textAlign:'left',fontWeight:500,color: '#198ae3',flex:1}}>Telefono</span>
					<input style={{flex:2,borderRadius:10,border:'1px solid lightgray',fontSize: '14px',padding:5}} placeholder='(+xx) xxx xxxxxxx' type='tel' onChange={(e) => {
						this.setState({
							phoneNumberContactUs: e.target.value
						})
					}}></input>
				</div>
				<div style={{display:'flex',flexDirection:'column', gap: 5, flex:1,alignItems:'left'}}>
					<span style={{textAlign:'left',fontWeight:500,color: '#198ae3',flex:1}}>Messaggio</span>
					<textarea style={{flex:1,borderRadius:10,border:'1px solid lightgray',fontSize: '14px',padding: 5}} placeholder='Scrivi il tuo messaggio qui'
					rows={4} type='text' onChange={(e) => {
						this.setState({
							messageContactUs: e.target.value
						})
					}}></textarea>
				</div>
				<div style={{display:'flex',flex:1,alignItems:'center',justifyContent:'center'}}>
					{ this.state.isLoading ? <LoaderContainer position={'absolute'}/> :
					<div onClick={() => {
					this.sendRequest()}} style={{borderRadius:10,backgroundColor:'#198ae3',padding:10,cursor: 'pointer', paddingLeft: '40px', paddingRight: '40px'}}>
					<span style={{color:'white'}}>Invia</span>
					</div>
					}
				</div>
			</div>
			</ModalComponent>
			}
			</>
		);
	}
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Navbar));
