import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
	setAllFormData,
	setHasUnreadMessage,
	setLoader,
	setReplied,
	setUser,
	setUserForm,
} from "./redux/slices/userSlice";

const withNavigation = (Component) => {
	return (props) => <Component {...props} history={useHistory()} />;
};

const mapStateToProps = (state) => ({
	state: state,
});

const mapDispatchToProps = (dispatch) => ({
	setUserAction: (user) => dispatch(setUser(user)),
	setLoaderAction: (data) => dispatch(setLoader(data)),
	setAllFormDataAction: (formData) => dispatch(setAllFormData(formData)),
	setUserForm: (data) => dispatch(setUserForm(data)),
	setRepliedAction: (data) => dispatch(setReplied(data)),
	setHasUnreadMessageAction: (data) => dispatch(setHasUnreadMessage(data)),
});

class App extends Component {
	state = {};

	componentDidMount() {
		const getUser = async () => {
			try {
				this.props.setLoaderAction(true);
				const { data } = await axios.get(
					`${process.env.REACT_APP_URL}/v1/user/me`,
					{ withCredentials: true }
				);
				const res = await axios.get(
					`${process.env.REACT_APP_URL}/v1/form/all`,
					{ withCredentials: true }
				);
				console.log('res ... . . ', res)
				/*
				const { data: messageData } = await axios.get(
					`${process.env.REACT_APP_URL}/v1/message/all`,
					{ withCredentials: true }
				);
				*/
				//console.log('data-user ... ', data.user);
				//console.log('res.data.userForm', res.data.userForm)
				
				data.user.isConnectAlliance = res.data.userForm[0].isConnectAlliance;

				this.props.setUserAction(data.user);
				this.props.setAllFormDataAction(res.data.allFormData);
				this.props.setUserForm(res.data.userForm);

				if (data.user.role === "user") {
					this.props.history.push(
						res.data.userForm.length > 0
							? `/dashboard`
							: "/form-elements/basic-elements"
					);
				}
				this.props.setLoaderAction(false);
				
				/*
				const responders = [];
				
				for (const chat of messageData.messagesList) {
					const { user: currentUser, chats: chatMessages } = chat;
					const { sender } = chatMessages[0];

					if (sender._id === data.user._id) {
						if (
							chatMessages.some(
								(msg) => msg.user._id === data.user._id
							)
						) {
							responders.push(currentUser._id);
						}
					} else {
						responders.push(currentUser._id);
					}
				}
				if (
					messageData.allMessages.filter((item) => {
						if (item.sender._id === data.user?._id) {
							return false;
						}

						return !item.hasSeen;
					}).length > 0
				) {
					this.props.setHasUnreadMessageAction(true);
				}

				this.props.setRepliedAction(responders);
				*/
			} catch (err) {
				this.props.setLoaderAction(false);
			}
		};
		getUser();

		this.onRouteChanged();
	}
	render() {
		let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
		let sidebarComponent = !this.state.isFullPageLayout && this.props?.state?.userState?.user ? <Sidebar /> : "";
		let SettingsPanelComponent = !this.state.isFullPageLayout ? (
			<SettingsPanel />
		) : (
			""
		);
		let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";

		

		return (
			<div className="container-scroller">
				{navbarComponent}
				<div className="container-fluid page-body-wrapper">
					{sidebarComponent}
					<div className="main-panel">
						<div className="content-wrapper">
							<AppRoutes />
							{SettingsPanelComponent}
						</div>
						{footerComponent}
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const { i18n } = this.props;
		const body = document.querySelector("body");
		if (this.props.location.pathname === "/layout/RtlLayout") {
			body.classList.add("rtl");
			i18n.changeLanguage("ar");
		} else {
			body.classList.remove("rtl");
			i18n.changeLanguage("en");
		}
		window.scrollTo(0, 0);
		const fullPageLayoutRoutes = [
			"/user-pages/login-1",
			"/user-pages/register-1",
			"/user-pages/password/forget",
			"/error-pages/error-404",
			"/error-pages/error-500",
			"/general-pages/landing-page",
		];

		let isFullPage = false;
		const path = this.props.location.pathname;
		const lastSlashIndex = path.lastIndexOf("/");

		// Slice the path from the start to the last slash
		const slicedPath = path.substring(0, lastSlashIndex);

		// Check if there is a string after the last slash
		const lastSegment = path.substring(lastSlashIndex + 1);
		if (
			slicedPath.length > 0 &&
			slicedPath !== "/" &&
			lastSegment.length > 0 &&
			(slicedPath === "/email/verify" ||
				slicedPath === "/user-pages/password/new")
		) {
			isFullPage = true;
		}

		for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
			if (
				this.props.location.pathname === fullPageLayoutRoutes[i] ||
				isFullPage
			) {
				this.setState({
					isFullPageLayout: true,
				});
				document
					.querySelector(".page-body-wrapper")
					.classList.add("full-page-wrapper");
				break;
			} else {
				this.setState({
					isFullPageLayout: false,
				});
				document
					.querySelector(".page-body-wrapper")
					.classList.remove("full-page-wrapper");
			}
		}
	}
}
// 

export default withTranslation()(withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(App))));
